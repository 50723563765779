import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar/navbar';

function App() {
  return (
    <div className="App">
      <div className='col-12'>
        <div className='row'>
        </div>
        <div className='row'>
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Welcome to sean-owen.com!
            </p>
          </header>
        </div>
      </div>
    </div>



  );
}

export default App;
